import { IRoseAGGridColumn } from '@rose/common-ui';
import { watch, Ref } from 'vue';

export function useAgGridReactiveHideCondition(columnDef: IRoseAGGridColumn<any>[], gridColumnApi: Ref<any>) {
  let matchColumnHideState = () => {
    let showColumns = columnDef.filter(def => def.reactiveHideCondition === false);
    let hideColumns = columnDef.filter(def => def.reactiveHideCondition === true);

    // console.log(
    //   'show',
    //   showColumns.map(def => def.field),
    //   'hide',
    //   hideColumns.map(def => def.field),
    // );

    gridColumnApi.value?.setColumnsVisible(
      showColumns.map(def => def.field),
      true,
    );
    gridColumnApi.value?.setColumnsVisible(
      hideColumns.map(def => def.field),
      false,
    );
  };

  watch(columnDef, matchColumnHideState);

  return { matchColumnHideState };
}
